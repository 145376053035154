<template>
	<main class="main">
		<section class="container" v-if="blocks">
      <Crumbs :data="crumbs"></Crumbs>
			<div class="main__box">
				<h1 class="main__title">{{ blocks[0].title }}</h1>
				<div class="main__text-box main__text-box--full">
					<span class="main__image-float-box">
						<img
							class="main__image-float-bg"
							:src="topBg"
							:alt="blocks[0].title"
							aria-hidden="true"
						/>
						<img
							class="main__image-float"
							:src="blocks[0].image"
							:alt="blocks[0].title"
							aria-hidden="true"
						/>
					</span>

					<div class="editor" v-html="blocks[0].content"></div>
				</div>

				<div class="options options--no-padding flex flex--middle">
					<a
						:href="`tel:${getSetting.phone.value}`"
						class="options__link"
						v-if="getSetting"
					>
						<span class="icon icon--phone"></span>
						<span class="visually-hidden">
							{{ getSetting.phone.description }}
						</span>
						{{ getSetting.phone.value }}
					</a>
					или
					<button class="options__link" @click="showModalFeedback">
						<span class="icon icon--headphones"></span>
						Обратная связь
					</button>
				</div>
			</div>
		</section>

		<section class="main__gradient main__gradient--light">
			<div class="container">
				<ul class="support__list" v-if="blocks">
					<li class="support__item">
						<h3 class="support__title">
							{{ blocks[1].title }}
						</h3>
						<div class="support__box">
							<div
								class="support__content editor"
								v-html="blocks[1].content"
							></div>
						</div>
						<div class="support__box support__box--attention">
							<div
								class="support__content editor"
								v-html="blocks[1].content2"
							></div>
						</div>
					</li>
					<li v-if="false" class="support__item">
						<h3 class="support__title">
							{{ blocks[2].title }}
						</h3>
						<div class="support__box">
							<div class="support__logo-box">
								<img
									:src="require('@/assets/images/logos/logo_sinara-bank.svg')"
									alt="Банк Синара"
									class="support__logo"
								/>
							</div>
							<div
								class="support__content support__content--center editor flex flex--column flex--center flex--grow"
							>
								<h4>
									{{ blocks[2].subtitle }}
								</h4>
								<div v-html="blocks[2].content"></div>
								<div class="support__content-link-box flex flex--center">
									<a href="tel:88001000600" class="support__link">
										<span class="icon icon--phone"></span>
										8 (800) 100 06 00
									</a>
									<a
										href="https://sinara.ru/"
										target="_blank"
										class="support__link"
									>
										<span class="icon icon--globe"></span>
										sinara.ru
									</a>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</section>

		<section class="main__box" v-if="blocks">
			<div class="container">
				<Info :data="[blocks[3], blocks[4]]"></Info>
			</div>
		</section>

		<div class="bg bg--gray last" v-if="blocks">
			<section class="main__box main__box--big">
				<div class="container">
					<h2 class="main__title main__title--center">{{ blocks[5].title }}</h2>
					<div class="flex flex--center">
						<button class="button" @click="showModalFeedback">
							Задать вопрос
						</button>
					</div>
				</div>
			</section>
		</div>
	</main>
</template>

<script>
import Info from '@/components/Info.vue'
import Crumbs from '@/components/Crumbs'

export default {
	name: 'Support',

	components: {
		Info,
		Crumbs,
	},

	data() {
		return {
		  crumbs: null,
			topBg: require('@/assets/images/animation/animation_circle-pattern.svg'),
		}
	},

	methods: {
		showModalFeedback() {
			this.$store.dispatch('GET_MODAL', { type: 2 })
		},
	},

	computed: {
		blocks() {
			const blocks = this.$store.getters.BLOCKS

			return blocks[this.$options.name]
		},

		getSetting() {
			return this.$store.getters.SETTING
		},
	},

	mounted() {
		this.$store.dispatch('GET_BLOCKS', {
			page: this.$route.fullPath,
			block: this.$options.name,
		})

    this.crumbs = [
      {
        link: 'HolderHome',
        text: 'Главная',
      },
      {
        link: null,
        text: 'Поддержка',
      },
    ]
	},
}
</script>
